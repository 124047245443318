import url from '../urls'
import { msalInstance } from 'pages/_app'
import { loginRequest } from 'authConfig'
import { reportError } from 'lib/useErrorHandler'

// TODO there is no reason for this to be class, expose pure functions to reduce memory consumption

/*
    TODO refactor this insane thing:
    - The methods _fetchPatch, _fetchDelete, etc. should be collated. The only difference is the METHOD.
    - There's no need to make this a class, best just export functions
    - Why extract the urls to a different file? Seems to complicate things for the sake of it
    - ideally we make these functions a hook (using use-swr or react-query), so we can display a toast when something goes wrong using useErrorHandler
*/
class FetchAdapter {
    getAccessToken = async (withRedirect = true) => {
        const activeAccount = msalInstance.getActiveAccount() // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
        const accounts = msalInstance.getAllAccounts()
        const loginRedirect = () =>
            msalInstance.loginRedirect(loginRequest).catch((err) => {
                reportError(err)
            })
        if (!activeAccount && accounts.length === 0) {
            if (withRedirect) {
                loginRedirect();
            }
            return
        }

        const request = { ...loginRequest, account: activeAccount || accounts[0] }
        const authResult = await msalInstance.acquireTokenSilent(request).catch(async (error) => {
            reportError(error)
            return await msalInstance.acquireTokenRedirect(request).catch((err) => {
                reportError(err)
                if (withRedirect) {
                    loginRedirect();
                }
                return
            })
        })

        if (!authResult.accessToken) {
            if (withRedirect) {
                loginRedirect();
            }
            return
        }

        return authResult.accessToken
    }

    // Analytics
    getAnalyticsStats(params) {
        const endpoint = url.api.BASE + url.api.ANALYTICS_STATS + `?${params}`
        return this._fetchGet(endpoint, 'json')
    }

    getAnalyticsContentItems(params) {
        const endpoint = url.api.BASE + url.api.ANALYTICS_ITEMS + `?${params}`
        return this._fetchGet(endpoint, 'json')
    }

    async downloadAnalytics(params) {
        const endpoint = url.api.BASE + url.api.ANALYTICS_EXPORT + `?${params}`

        let res = await this._fetchGetWithResponse(endpoint);

        if (res.ok) {
            let disposition = res.headers.get('content-disposition');
            let fileName = 'FDNAnalyticsRawExportedData.csv'; // For sanity: Default name if server name is not returned.

            // credits: https://stackoverflow.com/a/40940790
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, '');
                }
            }
            return { fileName: fileName, content: await res.text() }
        } else {
            return new Promise(async (result) => result({ error: true, status: res.status, message: await res.text() }))
        }
    }

    // Analytics V2
    getAnalytics(query, analyticsFilter) {
        const endpoint = url.api.BASE + url.api.ANALYTICS_STATS + `/${query}`;
        return this._fetchPost(endpoint, JSON.stringify(analyticsFilter), 'json');
    }

    // Authentication
    otpLogin(email) {
        const endpoint = url.api.BASE + url.api.OTP_LOGIN;
        return this._fetchPost(endpoint, JSON.stringify({ email: email }), 'json', 'json', true, true);
    }

    verifyOtp(email, otp) {
        const endpoint = url.api.BASE + url.api.VERIFY_OTP;
        return this._fetchPost(endpoint, JSON.stringify({ email: email, otp: otp }), 'json', 'json', true, true);
    }

    isAuthenticated() {
        const endpoint = url.api.BASE + url.api.IS_OTP_AUTHENTICATED;
        return this._fetchGet(endpoint, 'json', null, null, true);
    }

    refreshToken() {
        const endpoint = url.api.BASE + url.api.REFRESH_TOKEN;
        return this._fetchGet(endpoint, 'json', null, null, true);
    }

    logout() {
        const endpoint = url.api.BASE + url.api.OTP_LOGOUT;
        return this._fetchDelete(endpoint, null, null, null, true);
    }

    // Company
    getCompany(companyId) {
        const endpoint = url.api.BASE + url.api.COMPANY + `/${companyId}`
        return this._fetchGet(endpoint)
    }

    updateCompany(company) {
        const endpoint = url.api.BASE + url.api.COMPANY
        return this._fetchPatch(endpoint, company, null, null)
    }

    getCompanyDomains(companyId) {
        const endpoint = url.api.BASE + url.api.COMPANY_DOMAINS + `/${companyId}`
        return this._fetchGet(endpoint)
    }

    createCompanyDomain(domain) {
        const endpoint = url.api.BASE + url.api.COMPANY_DOMAINS
        return this._fetchPost(endpoint, domain, 'json', null)
    }

    deleteCompanyDomain(companyId, domain) {
        const endpoint = url.api.BASE + url.api.COMPANY_DOMAINS + `?companyId=${companyId}&companyDomainId=${domain}`
        return this._fetchDelete(endpoint)
    }

    getDevelopmentTypes(companyId) {
        const endpoint = url.api.BASE + url.api.DEVELOPMENT_TYPES + `/${companyId}`
        return this._fetchGet(endpoint)
    }

    createDevelopmentType(body) {
        const endpoint = url.api.BASE + url.api.DEVELOPMENT_TYPES
        return this._fetchPost(endpoint, body, 'json', null)
    }

    updateDevelopmentType(body) {
        const endpoint = url.api.BASE + url.api.DEVELOPMENT_TYPES
        return this._fetchPatch(endpoint, body, 'json', null)
    }

    deleteDevelopmentType(typeId) {
        const endpoint = url.api.BASE + url.api.DEVELOPMENT_TYPES + `/${typeId}`
        return this._fetchDelete(endpoint)
    }

    // DataSets
    getDatasetSchema(datasetId) {
        const endpoint = url.api.BASE + url.api.SCHEMAS + `/for/${datasetId}`
        return this._fetchGet(endpoint)
    }

    getDataset(datasetId) {
        const endpoint = url.api.BASE + url.api.DATASETS + `/data/${datasetId}`
        return this._fetchGet(endpoint)
    }

    getDevelopmentDatasets(developmentId) {
        const endpoint = url.api.BASE + url.api.DATASETS + `/${developmentId}`
        return this._fetchGet(endpoint)
    }

    getDevelopmentSchemas(developmentId) {
        const endpoint = url.api.BASE + url.api.SCHEMAS + `/development/${developmentId}`
        return this._fetchGet(endpoint)
    }

    updateSchema(schema) {
        const endpoint = url.api.BASE + url.api.SCHEMAS
        return this._fetchPatch(endpoint, schema, 'json', null)
    }

    saveDataset(dataset) {
        const endpoint = url.api.BASE + url.api.DATASETS + '/data'
        return this._fetchPost(endpoint, dataset)
    }

    updateDataset(dataset) {
        const endpoint = url.api.BASE + url.api.DATASETS + '/data'
        return this._fetchPatch(endpoint, dataset, 'json', null)
    }

    updateDatasetCSV(dataset) {
        const endpoint = url.api.BASE + url.api.DATASETS + '/data/csv'
        return this._fetchPut(endpoint, dataset, 'json', null)
    }

    deleteDataset(datasetId) {
        const endpoint = url.api.BASE + url.api.DATASETS + `/data/${datasetId}`
        return this._fetchDelete(endpoint)
    }

    // Developments
    getDevelopments(companyId) {
        const endpoint = url.api.BASE + url.api.DEVELOPMENTS + '/' + companyId + '?detailLevel=full'
        return this._fetchGet(endpoint)
    }

    getDevelopment(companyId, developmentId) {
        const endpoint = url.api.BASE + url.api.DEVELOPMENTS + `/${companyId}` + `/${developmentId}?detailLevel=full`
        return this._fetchGet(endpoint)
    }

    getDevelopmentMembers(developmentId) {
        const endpoint = url.api.BASE + url.api.DEVELOPMENT_MEMBERS + `/${developmentId}`
        return this._fetchGet(endpoint)
    }

    getDevelopmentThumb(developmentId) {
        const endpoint = url.api.BASE + url.api.DEVELOPMENT_THUMB + `/${developmentId}`
        return this._fetchGet(endpoint, 'blob')
    }

    /**
     * @param {Object} body
     * @param {boolean} isEdit
     * @returns {Promise<unknown>}
     */
    saveDevelopment(body, isEdit) {
        const endpoint = url.api.BASE + url.api.DEVELOPMENT_SAVE
        if (isEdit) {
            return this._fetchPatch(endpoint, body, null, null)
        } else {
            return this._fetchPost(endpoint, body, null)
        }
    }

    // GeneralCms
    getRoles() {
        const endpoint = url.api.BASE + url.api.ROLES
        return this._fetchGet(endpoint)
    }

    getColumnTypes = () => {
        const endpoint = url.api.BASE + url.api.COLUMN_TYPES
        return this._fetchGet(endpoint)
    }

    // Presentations
    getPresentations(developmentId) {
        const endpoint = url.api.BASE + url.api.PRESENTATIONS + `/${developmentId}?detailLevel=full`
        return this._fetchGet(endpoint)
    }

    /**
     * @param {string} developmentId
     * @param {string} presentationId
     * @param {string} detailLevel
     * - full - fills the entire object with all details, it's the slowest version of this method
     * - surface - fills only the surface level properties
     * - light - fills only the name, and thumbnail
     * @returns {Promise<unknown>}
     */
    getPresentation(developmentId, presentationId, detailLevel = 'full') {
        const endpoint = url.api.BASE + url.api.PRESENTATIONS + `/${developmentId}/${presentationId}?detailLevel=${detailLevel}`
        return this._fetchGet(endpoint)
    }

    getPresentationById(presentationId, detailLevel = 'full') {
        const endpoint = url.api.BASE + url.api.PRESENTATIONS_ID + `/${presentationId}?detailLevel=${detailLevel}`
        return this._fetchGet(endpoint)
    }

    /**
     * @param {Object} body
     * @returns {Promise<unknown>}
     */
    savePresentation(body, isEdit) {
        const endpoint = url.api.BASE + url.api.PRESENTATIONS
        if (isEdit) {
            return this._fetchPatch(endpoint, body, null, null)
        } else {
            return this._fetchPost(endpoint, body, null)
        }
    }

    /**
     * @param {string} developmentId
     * @returns {Promise<unknown>}
     */
    getArchivedPresentations(developmentId) {
        const endpoint = url.api.BASE + url.api.PRESENTATIONS + `/archived/${developmentId}?detailLevel=full`
        return this._fetchGet(endpoint)
    }

    /**
     * @param {string} presentationId
     * @returns {Promise<unknown>}
     */
    archivePresentation(presentationId) {
        const endpoint = url.api.BASE + url.api.PRESENTATIONS + `/archive/${presentationId}`
        return this._fetchDelete(endpoint, 'json', 'json')
    }

    archiveDevelopment(developmentId) {
        const endpoint = url.api.BASE + url.api.DEVELOPMENTS + `/archive/${developmentId}`
        return this._fetchPatch(endpoint, null, null, null)
    }

    unArchiveDevelopment(developmentId) {
        const endpoint = url.api.BASE + url.api.DEVELOPMENTS + `/unarchive/${developmentId}`
        return this._fetchPut(endpoint, null, 'json', 'text')
    }

    getPresentationThumb(presentationId) {
        const endpoint = url.api.BASE + url.api.PRESENTATION_THUMB + `/${presentationId}`
        return this._fetchGet(endpoint, 'blob')
    }

    /**
     * @param {string} presentationId
     * @returns {Promise<unknown>}
     */
    restorePresentation(presentationId) {
        const endpoint = url.api.BASE + url.api.PRESENTATIONS + `/restore/${presentationId}`
        return this._fetchPut(endpoint, null, 'json', 'text')
    }

    duplicatePresentation(presentationId) {
        const endpoint = url.api.BASE + url.api.PRESENTATIONS + `/duplicate/${presentationId}`
        return this._fetchGet(endpoint)
    }

    getPresentationDuplicationStatus(duplicationId) {
        const endpoint = url.api.BASE + url.api.PRESENTATIONS + `/duplicate/status/${duplicationId}`
        return this._fetchGet(endpoint)
    }

    abortDuplication(duplicationId) {
        const endpoint = url.api.BASE + url.api.PRESENTATIONS + `/duplicate/abort/${duplicationId}`
        return this._fetchDelete(endpoint)
    }

    // Settings
    getSettings(scope, targetId) {
        const endpoint = url.api.BASE + url.api.SETTINGS + '/' + scope + (targetId ? `/${targetId}` : '')
        return this._fetchGet(endpoint)
    }

    getStyleSettings(scope, targetId, key) {
        const endpoint = `${url.api.BASE}${url.api.STYLE_SETTINGS}/${scope}/${targetId}/${key}`;
        return this._fetchGet(endpoint);
    }

    saveSettings(body) {
        const endpoint = url.api.BASE + url.api.SAVE_SETTINGS
        return this._fetchPut(endpoint, body, 'json', null)
    }

    deleteSetting(scope, targetId, key) {
        const endpoint = url.api.BASE + url.api.SAVE_SETTINGS + '/' + scope + '/' + targetId + '/' + key
        return this._fetchDelete(endpoint)
    }

    //////////////

    getFonts(scope, targetId) {
        const endpoint = url.api.BASE + url.api.FONTS_LIST + '/' + scope + '/' + targetId
        return this._fetchGet(endpoint)
    }

    saveFonts(bodies) {
        const endpoint = url.api.BASE + url.api.FONTS
        return Promise.all(bodies.map((body) => this._fetchPut(endpoint, body)))
    }

    deleteFont(scope, targetId, key) {
        const endpoint = url.api.BASE + url.api.FONTS + '/' + scope + '/' + targetId + '/' + key
        return this._fetchDelete(endpoint)
    }

    getColours(scope, targetId, showUniqueOnly = false) {
        const endpoint = url.api.BASE + url.api.BRUSHES_LIST + '/' + scope + '/' + targetId + `?showUniqueOnly=${showUniqueOnly}`
        return this._fetchGet(endpoint)
    }

    saveColours(bodies) {
        const endpoint = url.api.BASE + url.api.BRUSHES
        return Promise.all(bodies.map((body) => this._fetchPut(endpoint, body)))
    }

    deleteColour(scope, id, key, body) {
        const endpoint = url.api.BASE + url.api.BRUSHES + '/' + scope + '/' + id + '/' + key
        return this._fetchDelete(endpoint, body)
    }

    // User

    /**
     * @param {number} id
     * @param {string} detailLevel // Light - just name and id, Surface - no nested sub-object, Full - the entire schema is filled
     * @returns {Promise<unknown>}
     */
    getUserById(id, detailLevel = 'Light') {
        const endpoint = url.api.BASE + url.api.USER_MANAGEMENT + '/' + id + '/?detailLevel=' + detailLevel
        return this._fetchGet(endpoint)
    }

    getAllUserPreferences() {
        const endpoint = url.api.BASE + url.api.GET_ALL_USER_PREFRENCES;
        return this._fetchGet(endpoint);
    }

    getSingleUserPreferences(key) {
        const endpoint = url.api.BASE + url.api.GET_SINGLE_USER_PREFRENCES + `/${key}`;
        return this._fetchGet(endpoint);
    }

    updateUserPreference(key, body) {
        const endpoint = url.api.BASE + url.api.SAVE_USER_PREFRENCES + `/${key}`;
        return this._fetchPost(endpoint, body, 'json', null);
    }

    getCurrentUser() {
        const endpoint = url.api.BASE + url.api.GET_USER
        return this._fetchGet(endpoint)
    }

    getCompanyUsers(companyId) {
        const endpoint = url.api.BASE + url.api.USERS_COMPANY + `/${companyId}`
        return this._fetchGet(endpoint)
    }

    inviteUser(body) {
        const endpoint = url.api.BASE + url.api.INVITE_USER
        return this._fetchPost(endpoint, body, 'json', null)
    }

    reinviteUser(body) {
        const endpoint = url.api.BASE + url.api.REINVITE_USER
        return this._fetchPost(endpoint, body, 'json', null)
    }

    deleteUserFromCompany(id, companyId) {
        const endpoint = url.api.BASE + url.api.USERS_COMPANY + `/${id}/${companyId}`
        return this._fetchDelete(endpoint, 'json', 'json')
    }

    getPermissions(scope, targetId) {
        const endpoint = url.api.BASE + url.api.USERS_PERMISSIONS + `/${scope}/${targetId}`
        return this._fetchGet(endpoint, 'json', null)
    }

    getPermissionsList(userId, companyId) {
        const endpoint = url.api.BASE + url.api.USERS_PERMISSIONS_LIST + `/${userId}/${companyId}`
        return this._fetchGet(endpoint, 'json', null)
    }

    updatePermissions(body) {
        const endpoint = url.api.BASE + url.api.USERS_PERMISSIONS
        return this._fetchPut(endpoint, body, 'json', null)
    }

    deletePermission(id) {
        const endpoint = `${url.api.BASE}${url.api.USERS_PERMISSIONS}/${id}`
        return this._fetchDelete(endpoint, 'json', 'json')
    }

    // TODO
    getUsers(scope, targetId) {
        // TODO - API needs /api/UserManagement/list/:scope/:target adding
        // const endpoint = url.api.BASE + url.api.USERS_LIST + '/' + scope + '/' + targetId
        const apiRoute = parseInt(scope) === 0 ? 'c' : parseInt(scope) === 1 ? 'd' : 'p'
        const endpoint = url.api.BASE + url.api.USER_MANAGEMENT + `/${apiRoute}/${targetId}`
        return this._fetchGet(endpoint)
    }

    searchCompanyUsers(companyId, query) {
        const endpoint = url.api.BASE + url.api.SEARCH_USERS + '/' + companyId + '/' + query
        return this._fetchGet(endpoint)
    }

    getPresentationMenu(presentationId) {
        const endpoint = url.api.BASE + url.api.PRESENTATION_MENU + '/' + presentationId
        return this._fetchGet(endpoint)
    }

    savePresentationMenu(body) {
        const endpoint = url.api.BASE + url.api.PRESENTATION_MENU
        return this._fetchPost(endpoint, body)
    }

    /**
     *
     * @param {number} scope // company, development or presentation
     * @param {number} id // companyId, developmentId, presentationId based on the scope parameter
     */
    getIcons(scope, id) {
        const endpoint = url.api.BASE + url.api.ICONS_LIST + '/' + scope + '/' + id
        return this._fetchGet(endpoint)
    }

    saveIcons(bodies) {
        const endpoint = url.api.BASE + url.api.ICONS
        return Promise.all(bodies.map((body) => this._fetchPut(endpoint, body)))
    }

    deleteIcons(scope, id, key, body) {
        const endpoint = url.api.BASE + url.api.ICONS + '/' + scope + '/' + id + '/' + key
        return this._fetchDelete(endpoint, body)
    }

    getDevelopmentRootFiles(companyId, developmentId, pageNo = 0, pageSize = 100, showOnlyMediaFiles = false) {
        const endpoint = url.api.BASE + url.api.DEVELOPMENT_FILES + '/' + companyId + '/' + developmentId + '/' + pageNo + '/' + pageSize + '/?showOnlyMediaFiles=' + showOnlyMediaFiles
        return this._fetchGet(endpoint)
    }

    getRootFiles(companyId, pageNo = 0, pageSize = 100, filter = '', showOnlyMediaFiles = false) {
        const filterParam = filter.length > 0 && !filter.includes('none') ? `&filter=${filter}` : ''

        const endpoint = url.api.BASE + url.api.ROOT_FILES + '/' + companyId + '/' + pageNo + '/' + pageSize + '/?showOnlyMediaFiles=' + showOnlyMediaFiles + '' + filterParam
        return this._fetchGet(endpoint)
    }

    getRootFolderId(companyId) {
        const endpoint = url.api.BASE + url.api.ROOT_FOLDER_ID + '/' + companyId
        return this._fetchGet(endpoint)
    }

    getDevelopmentRootFolderId(companyId, developmentId) {
        const endpoint = url.api.BASE + url.api.ROOT_FOLDER_ID + '/' + companyId + '/' + developmentId
        return this._fetchGet(endpoint)
    }

    // Start content library requests
    /**
     * @param {string} folderId
     * @param {number} pageNo
     * @param {number} pageSize
     * @param {string} filter
     * @param {boolean} showOnlyMediaFiles
     */
    getFolderFiles(folderId = '', { pageNo = 0, pageSize = 100, filter = '', showOnlyMediaFiles = false, sort = null }) {
        const filterParam = filter.length > 0 && !filter.includes('none') ? `&filter=${filter}` : ''
        if (!folderId) return null
        let endpoint = url.api.BASE + url.api.FILES_FOLDER + '/' + folderId + '/' + pageNo + '/' + pageSize + '/?showOnlyMediaFiles=' + showOnlyMediaFiles
        if (filterParam) endpoint = `${endpoint}${filterParam}`
        if (!isNaN(parseInt(sort))) endpoint = `${endpoint}&sortDirection=1&sortBy=${sort}`
        return this._fetchGet(endpoint)
    }

    /**
     * @param {number} fileId
     */
    getThumbnailMetaFile(fileId) {
        const endpoint = url.api.BASE + url.api.FILE_THUMBNAIL + '/' + fileId
        return this._fetchGet(endpoint, 'blob')
    }

    getThumbnailMetaFileByAssetId(assetId = '') {
        const endpoint = url.api.BASE + url.api.FILE_THUMBNAIL_BY_ASSET_ID + '/' + assetId
        return this._fetchGet(endpoint, 'blob')
    }

    getRequestUpload(body) {
        const endpoint = url.api.BASE + url.api.REQUEST_UPLOAD
        return this._fetchPost(endpoint, body)
    }

    uploadFile(body) {
        const endpoint = url.api.BASE + url.api.UPLOAD
        return this._fetchPost(endpoint, body, null, 'blob')
    }

    removeFile(id) {
        const endpoint = url.api.BASE + url.api.DELETE_FILE + '/' + id
        return this._fetchDelete(endpoint)
    }

    lockFile(id, lockStatus, sessionId) {
        const endpoint = `${url.api.BASE}${url.api.LOCK_FILE}/${id}/${lockStatus}/${sessionId}`;
        return this._fetchPost(endpoint, null, 'json', null, true);
    }

    async unlockFileBeacon(id) {
        const endpoint = `${url.api.BASE}${url.api.LOCK_FILE}/${id}/0/false`;
        this._fetchPost(endpoint, null, null, null, true);
    }

    downloadFile(fileId) {
        const endpoint = url.api.BASE + url.api.DOWNLOAD_FILE + '/' + fileId
        return this._fetchGet(endpoint, 'blob')
    }

    downloadFileByAssetId(assetId) {
        const endpoint = url.api.BASE + url.api.DOWNLOAD_FILE_BY_ASSET_ID + '/' + assetId
        return this._fetchGet(endpoint, 'blob')
    }

    moveFile(body) {
        const endpoint = url.api.BASE + url.api.MOVE_FILE
        return this._fetchPut(endpoint, body)
    }

    createFolder(body) {
        const endpoint = url.api.BASE + url.api.CREATE_FOLDER
        return this._fetchPost(endpoint, body)
    }

    renameFolder(body) {
        const endpoint = url.api.BASE + url.api.RENAME_FOLDER
        return this._fetchPatch(endpoint, body, 'json', null)
    }

    renameFile(fileId, newName) {
        const endpoint = url.api.BASE + url.api.RENAME_FILE
        return this._fetchPatch(endpoint, JSON.stringify({ fileId: fileId, newName: newName }), 'json', null)
    }

    getFileInfo(assetId) {
        const endpoint = url.api.BASE + url.api.FILE_INFO_BY_ASSET_ID + '/' + assetId
        return this._fetchGet(endpoint)
    }

    getFileOrFolderInfoById(id) {
        const endpoint = url.api.BASE + url.api.FILE_INFO_BY_ID + '/' + id
        return this._fetchGet(endpoint)
    }

    searchFile(query) {
        const endpoint = url.api.BASE + url.api.SEARCH_FILE + '/1/0/200/' + query // companyId=1, PageNo=0, PageSize=200
        return this._fetchGet(endpoint)
    }
    // Stop content library requests

    // Start content types requests
    /**
     */
    getContentTypes() {
        const endpoint = url.api.BASE + url.api.CONTENT_TYPES
        return this._fetchGet(endpoint)
    }
    /**
     *
     * @param {number} id // content type id
     */
    getContentTypesThumbnail(id) {
        const endpoint = url.api.BASE + url.api.CONTENT_TYPE_THUMBNAIL + '/' + id
        return this._fetchGet(endpoint, 'blob')
    }

    createIddContentItem(body) {
        const endpoint = url.api.BASE + url.api.CREATE_IDD_CONTENT_ITEM
        return this._fetchPost(endpoint, body)
    }

    createMediaContentItem(body) {
        const endpoint = url.api.BASE + url.api.CREATE_MEDIA_CONTENT_ITEM
        return this._fetchPost(endpoint, body)
    }

    getIDDContentItem(assetId) {
        const endpoint = url.api.BASE + url.api.IDD_CONTENT_ITEM + '/' + assetId
        return this._fetchGet(endpoint)
    }

    saveIDDContentItem(body) {
        const endpoint = url.api.BASE + url.api.IDD_CONTENT_ITEM
        return this._fetchPut(endpoint, body, 'json', null)
    }

    getMediaContentItem(simpleContentType, assetId) {
        const endpoint = url.api.BASE + url.api.MEDIA_CONTENT_ITEM + '/' + simpleContentType + '/' + assetId
        return this._fetchGet(endpoint)
    }

    /**
     *
     * @param {object} body
     * @param {string} type // can be: video/mmc/gallery/weblink/panorama
     */
    saveMediaContentItem(body, type) {
        const endpoint = url.api.BASE + url.api.MEDIA_CONTENT_ITEM + '/' + type
        return this._fetchPut(endpoint, body, 'json', null)
    }

    // End content types requests

    /**
     * @param {string} url
     * @returns {Promise<Response | any>}
     * @private
     */

    async _fetchGetWithResponse(url) {
        const headers = new Headers()

        await this.appendAuthHeaders(headers);

        const options = {
            headers: headers,
        }

        return await fetch(url, options)
    }

    async _fetchGet(url, responseType = 'json') {
        const headers = new Headers()

        await this.appendAuthHeaders(headers);

        const options = {
            headers: headers,
        }

        const res = await fetch(url, options)

        if (res.ok) {
            switch (responseType) {
                case 'blob':
                    return await res.blob()
                case 'text':
                    return res.text()
                default:
                    return await res.json()
            }
        } else {
            await this.handleUnauthorizedError(res);
            // return a successful promise for now until errors are handled across the app
            return new Promise(async (result) => result({ error: true, status: res.status, message: await res.text() }))
            //throw new Error(res.status + " " + await res.text());
        }
    }

    /**
     * @param {string} url
     * @param {Object} body
     * @param {string} contentType
     * @param {string} responseType
     * @returns {Promise<Response>}
     * @private
     */
    async _fetchPost(url, body, contentType = 'json', responseType = 'json', keepAlive = false, anonymous = false) {
        const headers = new Headers()

        if (!anonymous) {
            await this.appendAuthHeaders(headers);
        }

        switch (contentType) {
            case null:
                break
            default:
                headers.append('Content-Type', 'application/json')
        }
        const options = {
            method: 'POST',
            headers: headers,
            body: body,
            keepalive: keepAlive,
        }

        const res = await fetch(url, options)
        if (res.ok) {
            switch (responseType) {
                case null:
                    break
                case 'blob':
                    return await res.blob()
                default:
                    return await res.json()
            }
        } else {
            await this.handleUnauthorizedError(res);
            throw new Error(res.status + ' ' + (await res.text()))
        }
    }

    /**
     * @param {string} url
     * @param {Object} body
     * @param {string} contentType
     * @param {string} responseType
     * @returns {Promise<Response>}
     * @private
     */
    async _fetchPut(url, body, contentType = 'json', responseType = 'json') {
        const headers = new Headers()
        await this.appendAuthHeaders(headers);

        switch (contentType) {
            case null:
                break
            default:
                headers.append('Content-Type', 'application/json')
        }
        const options = {
            method: 'PUT',
            headers: headers,
            body: body,
        }

        const res = await fetch(url, options)
        if (res.ok) {
            switch (responseType) {
                case null:
                    break
                case 'blob':
                    return await res.blob()
                case 'text':
                    return await res.text()
                default:
                    return await res.json()
            }
        } else {
            await this.handleUnauthorizedError(res);
            throw new Error(res.status + ' ' + (await res.text()))
        }
    }

    /**
     * @param {string} url
     * @param {Object} body
     * @param {string} contentType
     * @param {string} responseType
     * @returns {Promise<Response>}
     * @private
     */
    async _fetchPatch(url, body, contentType = 'json', responseType = 'json') {
        const headers = new Headers()

        await this.appendAuthHeaders(headers);

        switch (contentType) {
            case null:
                break
            default:
                headers.append('Content-Type', 'application/json')
        }
        const options = {
            method: 'PATCH',
            headers: headers,
            body: body,
        }

        const res = await fetch(url, options)
        if (res.ok) {
            switch (responseType) {
                case null:
                    break
                case 'blob':
                    return await res.blob()
                default:
                    return await res.json()
            }
        } else {
            await this.handleUnauthorizedError(res);
            throw new Error(res.status + ' ' + (await res.text()))
        }
    }

    /**
     * @param {string} url
     * @param {Object} body
     * @param {string} contentType
     * @param {string} responseType
     * @returns {Promise<Response>}
     * @private
     */
    async _fetchDelete(url, body, contentType = 'json', responseType = null) {
        const headers = new Headers()

        await this.appendAuthHeaders(headers);

        switch (contentType) {
            case null:
                break
            default:
                headers.append('Content-Type', 'application/json')
        }
        const options = {
            method: 'DELETE',
            headers: headers,
            body: body,
        }

        const res = await fetch(url, options)
        if (res.ok) {
            switch (responseType) {
                case 'json':
                    return await res.json()
                default:
                    break
            }
        } else {
            await this.handleUnauthorizedError(res);
            throw new Error(await res.text())
        }
    }

    async appendAuthHeaders(headers) {

        const msalAccessToken = await this.getAccessToken(false);
        
        if (msalAccessToken && window.location.href.includes('login')) {
            localStorage.removeItem('isAuthenticated');
            window.location.href = "./company"
        }

        if (localStorage.getItem('isAuthenticated') || window.location.href.includes('login') && !msalAccessToken) {
            headers.append('X-Use-OTP-JWT', "OTP");

            const OTP_LOCK_KEY = 'otpAccessTokenLock';
            const OTP_EXPIRATION_KEY = 'otpAccessTokenExpireDate';
            const TEN_MINUTES_IN_MS = 1000 * 60 * 10;
            const tokenExpireDateStr = localStorage.getItem(OTP_EXPIRATION_KEY);

            if (tokenExpireDateStr) {
                const tokenExpireDate = new Date(tokenExpireDateStr);
                const now = new Date().getTime();

                if (tokenExpireDate.getTime() - now <= TEN_MINUTES_IN_MS && !localStorage.getItem(OTP_LOCK_KEY)) {
                    try {
                        localStorage.setItem(OTP_LOCK_KEY, "true");

                        const refreshTokenResponse = await this.refreshToken();
                        if (!refreshTokenResponse?.success) {
                            localStorage.setItem('isAuthenticated', false);
                            localStorage.removeItem(OTP_EXPIRATION_KEY);
                        }
                        else {
                            localStorage.setItem(OTP_EXPIRATION_KEY, refreshTokenResponse.tokenExpireDate);
                        }
                    } catch (err) {
                        localStorage.setItem('isAuthenticated', false);
                        localStorage.removeItem(OTP_EXPIRATION_KEY);
                        console.error("Error refreshing token:", err);
                    }
                    finally {
                        localStorage.removeItem(OTP_LOCK_KEY);
                    }
                }
            }

        } else {
            const accessToken = await this.getAccessToken();
            const bearer = `Bearer ${accessToken}`;
            headers.append('Authorization', bearer);
        }
        return headers;
    }

    handleUnauthorizedError = async (response) => {
        if (response.status === 401 && window.location.href.includes('login') === false) {
            
            const refreshTokenResponse = await this.refreshToken();
            if (!refreshTokenResponse?.success) {
                this.removeOTPKeysAndRedirect();
            }

            const isAuthenticatedResponse = await this.isAuthenticated();
            if (!isAuthenticatedResponse?.isAuthenticated) {
                this.removeOTPKeysAndRedirect();
            }
        }
    }

    removeOTPKeysAndRedirect = () => {
        const OTP_EXPIRATION_KEY = 'otpAccessTokenExpireDate';
        localStorage.setItem('isAuthenticated', false);
        localStorage.removeItem(OTP_EXPIRATION_KEY);
        window.location.href = "./login"
    }

}

export default FetchAdapter
export const apiAdapter = new FetchAdapter()
export const swrFetcher = (url) => apiAdapter._fetchGet(`${process.env.NEXT_PUBLIC_API_URL_BASE}${url}`)
