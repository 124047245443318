import React, { useEffect, useState } from 'react'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import { loginRequest } from 'authConfig'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import styles from './LayoutBase.module.scss'
import { useErrorHandler } from 'lib/useErrorHandler'
import { useRouter } from 'next/router'
import Login from './Login';
import { ApiProvider } from 'lib/api/ApiContext'

const LoginLink = () => {
    const { instance } = useMsal()
    const { handleError } = useErrorHandler()
    const [isOtpLogin, setIsOtpLogin] = useState(false);
    const router = useRouter()

    const handleLogin = (e) => {
        e.preventDefault()
        instance.loginRedirect(loginRequest).catch((e) => {
            handleError(e)
        })
    }

    useEffect(() => {
        const isOtpLogin = router.asPath.indexOf("login") > 0 || router.asPath.indexOf("tcs") > 0;

        if (!isOtpLogin && !localStorage.getItem("otpAccessTokenExpireDate")) {
            localStorage.removeItem('isAuthenticated');
        }

        setIsOtpLogin(isOtpLogin);
    }, [router?.asPath])


    return (
        <>
        { !isOtpLogin &&  <Typography variant="h2">
            You are not signed in, please <a href="#" onClick={handleLogin} key="loginPopup" >
                login
            </a> first</Typography>
        }
        { isOtpLogin && <ApiProvider><Login /></ApiProvider> }
        </>
    )
}

export default function LayoutBase(props) {
    const [showLoginPage, setShowLoginPage] = useState(false);
    const router = useRouter();

    useEffect(() => {
        // TODO: OTP Auth - move isAuthenticated string to constants
        if (localStorage.getItem('isAuthenticated') != 'true' || window.location.href.includes?.('login'))
            setShowLoginPage(true);
        else
            setShowLoginPage(false);
    }, [router?.asPath]);

    return (
        <>
            <AuthenticatedTemplate>
                <Box className={styles.root}>{props.children}</Box>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                {showLoginPage ? <Box className={styles.fullCenter}>
                        <LoginLink />
                </Box> : <Box className={styles.root}>{props.children}</Box>}
            </UnauthenticatedTemplate>
        </>
    )
}
